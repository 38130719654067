<template lang="pug">
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'SignOut',
  methods: {
    ...mapActions('App', ['reset']),
    ...mapActions('Cognito', ['signOut'])
  },
  async mounted() {
    try {
      await this.signOut()
      this.reset()
    } catch (e) {
      // Silent
    } finally {
      window.location.href = ''
    }
  }
}
</script>
